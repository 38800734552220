<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main class="pa-0">
    <v-card>
      <v-card-title class="title pt-3">
        <span>Cambiar estado masivamente</span>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-form ref="archivoEstado">
          <div class="archivo ma-1" v-show="verCSV">
            <input :disabled="leyendoArchivo" type="file" class="seleccionarArchivo" accept=".csv"
              @change="lectorCSV($event)" ref="cargaDeRelaciones">
            <p v-if="validandoDocumento" style="padding-top: 0.6rem;">
              Espere un momento...
              <v-icon :class="{ 'rotate-animation': validandoDocumento }"
                large>rotate_right
              </v-icon>
            </p>
            <p v-else-if="formatoIncorrecto" style="color: red; margin-top: 1.4rem;">
              <v-icon class="mr-2" style="color: red;">error</v-icon>
              {{ mensajeIncorrecto }}
            </p>
            <p v-else>Arrastre un archivo .CSV con las relaciones de productos con bodegas,
              <br>
              También puede hacer click en este cuadro.
            </p>
          </div>
          <v-alert v-if="tabla" class="pa-2 ma-0" color="primary" transition="scale-transition" outlined>
            <h4 class="d-flex justify-center"><v-icon color="primary" class="mr-2">info</v-icon>{{ mensajeCantidad }}</h4>
          </v-alert>
          <v-simple-table class="tablaRelaciones mt-1" fixed-header height="370px" v-show="tabla"
            ref="tablaRelacionesMasivas" id="miTabla">
            <template v-slot:default>
              <thead class="header">
                <tr>
                  <th> PRODUCTO </th>
                  <th> BODEGA </th>
                  <th> ESTADO </th>
                  <th> VALIDACIÓN </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(registro, registroIndex) in registrosFiltrados"
                  :key="registroIndex">
                  <td>{{ registro.producto }}</td>
                  <td>{{ registro.bodega }}</td>
                  <td>{{ registro.estado }}</td>
                  <td>
                    <div
                      v-if="validacionesFiltradas[registroIndex] && validacionesFiltradas[registroIndex].length > 0">
                      <div v-for="(mensaje, subIndex) in validacionesFiltradas[registroIndex]"
                        :key="subIndex" class="error-message">
                        {{ mensaje }}
                      </div>
                    </div>
                    <div v-else>
                      <v-icon color="green">check_circle</v-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- Botones de cerrar y volver que se activan cuando se abre el dialogo para el cambio de estado masivo -->
          <div class="d-flex justify-end mt-2">
            <v-btn color="error" text :disabled="leyendoArchivo" @click="close()">CERRAR</v-btn>
            <v-btn v-if="mostrarBotones" color="blue" text @click="volverACargarDocumento">VOLVER</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

  </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import Papa from 'papaparse';

export default {
  name: 'EstadoProductoBodega',

  data() {
    return {
      tabla: false,
      verCSV: true,
      validandoDocumento: false,
      formatoIncorrecto: false,
      cantidadRegistros: null,
      registros: [],
      validaciones: [],
      mostrarBotones: false,
      usuario: '',
      mensajeIncorrecto: '',
      leyendoArchivo: false,
      totalBloques: null,
      bloquesProcesados: null,
      bloquesTemporales: [],
    }
  },

  mounted() {
    this.usuario = this.auth.username.toUpperCase();
  },

  computed: {
    ...mapState(["auth", "enterprise"]),
    /**
    * Devuelve la cantidad de registros que tienen mensajes de validación.
    * @returns {number} - La cantidad de registros con mensajes de validación.
    */
    registrosConError() {
      const registrosConError = this.validaciones.filter(validacion => validacion.length > 0);
      return registrosConError.length;
    },
    /**
    * Filtra y devuelve los registros que tienen mensajes de validación.
    * Si no hay registros con mensajes de validación, devuelve todos los registros.
    * @returns {Array} - Los registros que tienen mensajes de validación o todos los registros si no hay errores.
    */
    registrosFiltrados() {
      if (this.registrosConError > 0) {
        const registrosFiltrados = this.registros.filter((registro, index) => this.validaciones[index].length > 0);
        return registrosFiltrados;
      }
      return this.registros;
    },
    /**
    * Filtra y devuelve las validaciones que tienen mensajes de error.
    * Si no hay registros con mensajes de validación, devuelve todas las validaciones.
    * @returns {Array} - Las validaciones que tienen mensajes de error o todas las validaciones si no hay errores.
    */
    validacionesFiltradas() {
      if (this.registrosConError > 0) {
        return this.validaciones.filter((validacion, index) => this.validaciones[index].length > 0);
      }
      return this.validaciones;
    },
    /**
    * Verifica si existen errores en las validaciones.
    * @returns {boolean} - true si hay al menos un registro con mensajes de validación, false en caso contrario.
    */
    hayErrores() {
      return this.validaciones.some(validacion => validacion.length > 0);
    },
    /** Cuenta los registros con errores */
    cantidadRegistrosConError() {
      return this.registros.filter((registro, index) => this.validaciones[index].length > 0).length;
    },
    /** Establece un valor en un mensaje para detallar la cantidad de registros procesados y si tienen error */
    mensajeCantidad() {
      const validoText = this.cantidadRegistros === 1 ? 'Se validó' : 'Se validaron';
      const registrosText = this.cantidadRegistros === 1 ? 'registro' : 'registros';
      let mensaje = `${validoText} ${this.cantidadRegistros} ${registrosText}`;
      if (this.cantidadRegistrosConError > 0) {
        const erroresText = this.cantidadRegistrosConError === 1 ? 'presenta el siguiente error' : 'presentan los siguientes errores';
        mensaje += ` y ${this.cantidadRegistrosConError} ${erroresText}.`;
      } else {
        mensaje += '.';
      }
      return mensaje;
    },
  },

  methods: {

    /**
     * Lee y procesa un archivo CSV subido por el usuario.
     * Verifica el formato del archivo, analiza su contenido y realiza validaciones.
     * 
     * @param {Event} event - El evento de subida de archivo.
     */
    lectorCSV(event) {
      this.leyendoArchivo = true;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = '';
      const file = event.target.files[0];
      if (!file || file.name.split('.').pop() !== 'csv') {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Formato incorrecto, debe subir o arrastrar un archivo .csv.';
        this.leyendoArchivo = false;
        return;
      }

      this.validandoDocumento = true;
      this.registros = [];
      this.totalBloques = 0;
      this.bloquesProcesados = 0;
      this.bloquesTemporales = [];

      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const parsedData = Papa.parse(content, {
          header: false,
          delimiter: ';',
          skipEmptyLines: true,
        });

        if (!parsedData.data || parsedData.data.length === 0 || (parsedData.data.length === 1 && parsedData.data[0].length === 1 && parsedData.data[0][0] === '')) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }

        const headerRow = parsedData.data[0];
        const isHeaderRow = !(/^\d+$/.test(headerRow[0]) && /^\d+$/.test(headerRow[1]));
        const startingIndex = isHeaderRow ? 1 : 0;
        if (parsedData.data.length === 1 && isHeaderRow) { 
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo solo contiene encabezados. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }
        const chunkSize = 2000;
        let chunk = [];
        for (let index = startingIndex; index < parsedData.data.length; index++) {
          const row = parsedData.data[index];
          if (row[0] !== '' || row[1] !== '' || row[2] !== '') {
            chunk.push({
              producto: row[0],
              bodega: row[1],
              estado: row[2],
            });
          }
          if (chunk.length === chunkSize) {
            this.registros.push(...chunk);
            this.totalBloques++;
            this.bloquesTemporales.push([...chunk]);
            chunk = [];
          }
        }

        if (chunk.length > 0) {
          this.registros.push(...chunk);
          this.totalBloques++;
          this.bloquesTemporales.push([...chunk]);
        }

        this.cantidadRegistros = this.registros.length;
        if (this.registros.length === 0) { 
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'Por favor, suba un archivo con contenido válido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }

        this.validarRegistros().then(tieneErrores => {
          if (tieneErrores) {
            this.verificarValidaciones();
          } else {
            this.procesarRegistrosPorFragmentos();
          }
        });
      };

      reader.onerror = () => {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Error al leer el archivo. Verifique el contenido e inténtelo de nuevo.';
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      };
      reader.readAsText(file);
    },
    /**
     * Valida los registros en `this.registros` y genera una lista de registros numéricos válidos.
     * 
     * @returns {boolean} Indica si hubo errores en la validación.
     */
    async validarRegistros() {
      const registrosNumericos = [];
      const fecha = new Date();
      let tieneErrores = false;
      this.validaciones = [];

      this.registros.forEach((registro, index) => {
        const productoValido = /^\d+$/.test(registro.producto);
        const bodegaValida = /^\d+$/.test(registro.bodega);
        const estadoValido = /^[abAB]$/.test(registro.estado);
        let estadoListo = (registro.estado !== undefined && registro.estado !== null && estadoValido) ? registro.estado.trim().toUpperCase() : '';
        let mensajeError = "";
        if (!this.validaciones[index]) {
          this.validaciones[index] = [];
        }
        if (productoValido && bodegaValida && estadoValido) {
          const codBode = parseInt(registro.bodega, 10);
          if (codBode >= -32768 && codBode <= 32767) {
            registrosNumericos.push({ codEmpr: this.enterprise.code, codProd: registro.producto, codBode: registro.bodega, actUsua: this.usuario, actHora: fecha, actEsta: estadoListo });
          } else {
            registrosNumericos.push({ codEmpr: this.enterprise.code, codProd: '', codBode: '', actUsua: this.usuario, actHora: fecha, actEsta: estadoListo });
            mensajeError = "La bodega no existe en Stone";
            tieneErrores = true;
          }
        } else {
          registrosNumericos.push({ codEmpr: this.enterprise.code, codProd: '', codBode: '', actUsua: this.usuario, actHora: fecha, actEsta: estadoListo });
          if ((registro.producto === undefined || registro.producto === null || registro.producto === "") &&
            (registro.bodega === undefined || registro.bodega === null || registro.bodega === "") &&
            (registro.estado === undefined || registro.estado === null || registro.estado === "")) {
            mensajeError = "Los campos no pueden estar vacíos.";
          } else {
            if (registro.producto === undefined || registro.producto === null || registro.producto === "") {
              mensajeError += "El código de producto no puede estar vacío.\n";
            } else if (!productoValido) {
              mensajeError += "El código de producto debe ser solo numérico.\n";
            }

            if (registro.bodega === undefined || registro.bodega === null || registro.bodega === "") {
              mensajeError += "El código de bodega no puede estar vacío.\n";
            } else if (!bodegaValida) {
              mensajeError += "El código de bodega debe ser solo numérico.\n";
            }

            if (registro.estado === undefined || registro.estado === null || registro.estado === "") {
              mensajeError += "El estado no puede estar vacío.\n";
            } else if (!estadoValido) {
              mensajeError += "El estado no es válido. Debe ser 'A' o 'B'.\n";
            }
          }
          tieneErrores = true;
        }
        if (mensajeError) {
          this.validaciones[index].push(mensajeError);
        }
      });
      
      this.registros = [];
      return tieneErrores;
    },
    /**
     * Procesa los registros por fragmentos, cambiando los estados masivamente.
     * Los registros se procesan en bloques definidos por `this.bloquesTemporales`.
     */
    async procesarRegistrosPorFragmentos() {
      for (const registros of this.bloquesTemporales) {
        await this.cambiarEstadosMasivamente(registros);
      }
    },
    /**
     * Cambia los estados de los registros masivamente.
     * Realiza validaciones sobre cada registro y envía los registros válidos al backend.
     * 
     * @param {Array} registros - La lista de registros a validar y actualizar.
     * @returns {Promise} - Una promesa que se resuelve cuando los estados han sido actualizados.
     */
    async cambiarEstadosMasivamente(registros) {
      const registrosNumericos = [];
      const fecha = new Date();
      registros.forEach((registro) => {
        const estadoListo = registro.estado.trim().toUpperCase();
        registrosNumericos.push({ codEmpr: this.enterprise.code, codProd: registro.producto, codBode: registro.bodega, actUsua: this.usuario, actHora: fecha, actEsta: estadoListo });
      });
      try {
        const response = await this.$http.post("/msa-administration/hdPrbod/update-estados", registrosNumericos);
        if (response.status === 200) {
          this.bloquesProcesados++;
          this.verificarBloques();
        } else {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'Error al subir el archivo. Inténtelo de nuevo. Si persiste, contacte al administrador.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
        }
      } catch (error) {
        console.error(error);
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Error al subir el archivo. Inténtelo de nuevo. Si persiste, contacte al administrador.';
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      }
    },
    /**
     * Verifica el estado de los bloques procesados.
     * Si todos los bloques han sido procesados, llama al método this.verificarValidaciones().
     */
    verificarBloques() {
      if (this.bloquesProcesados === this.totalBloques) {
        this.verificarValidaciones();
      }
    },
    /**
     * Verifica las validaciones de los registros procesados.
     * Si hay errores de validación, muestra la tabla de errores.
     * Si no hay errores, cierra la vista y emite un evento al componente padre.
     */
    verificarValidaciones() {
      if (this.validaciones.some(val => val.length > 0)) {
        const registrosConErrores = [];
        const validacionesConErrores = [];

        this.bloquesTemporales.flat().forEach((registro, index) => {
          if (this.validaciones[index].length > 0) {
            registrosConErrores.push(registro);
            validacionesConErrores.push(this.validaciones[index]);
          }
        });

        this.registros = registrosConErrores;
        this.validaciones = validacionesConErrores;
        this.bloquesTemporales = [];
        this.tabla = true;
        this.verCSV = false;
        this.mostrarBotones = true;
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      } else {
        this.close();
        setTimeout(() => {
          this.$emit('mostrar-validacion', this.cantidadRegistros)
        }, 200);
      }
    },
    /**
    * Reinicia el estado del componente para volver a cargar un nuevo documento CSV.
    * Este método se encarga de reiniciar todas las variables de estado relacionadas con la
    * carga de documentos y las validaciones de manera que se pueda seleccionar y cargar
    * un nuevo archivo CSV para ser procesado nuevamente.
    */
    volverACargarDocumento() {
      this.validaciones = [];
      this.registros = [];
      this.validandoDocumento = false;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = false;
      this.verCSV = true;
      this.mostrarBotones = false;
      this.tabla = false;
      this.$refs.cargaDeRelaciones.value = '';
    },
    /**
     * Cierra el modal de carga de relaciones por medio de un evento emitido al padre y resetea los estados relacionados.
     */
    close() {
      this.validaciones = [];
      this.registros = [];
      this.validandoDocumento = false;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = false;
      this.verCSV = true;
      this.mostrarBotones = false;
      this.tabla = false;
      this.leyendoArchivo = false;
      if (this.$refs.cargaDeRelaciones) {
        this.$refs.cargaDeRelaciones.value = '';
      }
      this.$emit('close-estado')
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.title {
  background-color: #1867c0;
  color: white !important;
}
.archivo {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 1rem;
  height: 100px;
  position: relative;
  cursor: pointer;
}
.archivo p {
  margin-top: .7rem;
  text-align: center;
}
.seleccionarArchivo {
  opacity: 0;
  width: 98%;
  height: 85px;
  position: absolute;
  cursor: pointer;
}
.seleccionarArchivo:disabled {
  cursor: default;
}
.rotate-animation {
  animation: rotate 2s linear infinite;
  cursor: default;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
::v-deep .tablaRelaciones div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
.header tr {
  background-color: rgb(223, 223, 223) !important;
}
.header tr th {
  font-weight: bold;
  color: black !important;
}
.error-message {
  color: red;
  white-space: pre-line;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 25vw;
}
</style>
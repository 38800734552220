<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Junio 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div>
        <!-- Menú desplegable -->
        <v-menu>
            <template v-slot:activator="{ on }">
                <!-- Campo de texto de búsqueda -->
                <ValidationProvider ref="provider" v-slot="{ errors }" :rules="{ required: true, sinDatos: true, usuarioWasoluti: true }">
                    <v-text-field v-model="buscarTexto" label="Bodega" dense outlined :error-messages="buscarTextoError || errors"
                        @input="buscar" v-on="on">
                    </v-text-field>
                </ValidationProvider>
            </template>
            <!-- Lista de resultados -->
            <v-list v-show="mostrarLista && bodegasFiltradas.length > 0" class="resultadoLista" style="margin-top: 2.5rem;">
                <v-list-item v-for=" bodega  in  bodegasFiltradas " :key="bodega.value" @click="seleccionDeBodega(bodega)"
                    :class="{ seleccionada: itemSeleccionado === bodega }">
                    <v-list-item-title>{{ bodega.text }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationProvider, } from 'vee-validate';
import { mapState } from "vuex";
import { required } from 'vee-validate/dist/rules';
import Vue from "vue"

extend('required', {
    ...required,
    message: 'Este campo es requerido',
});

let enterpriseCode = null;

/** Validación para el campo de texto,
 * Valida que en el campo de texto se eliga un valor de la lista,
 * Valida que la bodega este activa en base de datos.
 */
extend('sinDatos', {
    validate: async (value) => {
        const tieneGuion = value.includes(' - ');
        if (!tieneGuion) {
            return 'Debes elegir un resultado de la lista';
        }
        const valorRecortado = value.trim();
        const [codBode, ...nomBodeParts] = valorRecortado.split(' - ');
        const nomBode = nomBodeParts.join(' - ');
        if (codBode.length === 0 || nomBode.length === 0) {
            return 'No se encontraron resultados para la búsqueda';
        }
        try {
            const response = await Vue.prototype.$http.get('msa-administration/inBodeg/listarBodegas', {
                params: {
                    codBode,
                    nomBode,
                    codEmpr: enterpriseCode,
                },
            });
            const bodegas = response.data;
            const valorFormateado = `${codBode} - ${nomBode}`.trim();
            const existeValorFormateado = bodegas.some((bodega) => {
                const bodegaFormateada = `${bodega.codBode} - ${bodega.nomBode}`.trim();
                return bodegaFormateada === valorFormateado;
            });
            if (!existeValorFormateado) {
                const elValorSeleccionadoExiste = bodegas.some((bodega) => {
                    const valorFormateado = `${bodega.codBode} - ${bodega.nomBode}`.trim();
                    return valorFormateado === valorRecortado && bodega.seleccionada === true;
                });
                if (!elValorSeleccionadoExiste) {
                    return 'Debes elegir un resultado de la lista';
                }
            }
            // Realizar la llamada al método de Spring Boot
            const responseVerificacion = await Vue.prototype.$http.get('msa-administration/inBodeg/validarEstado', {
                params: {
                    codBode,
                    nomBode,
                    codEmpr: enterpriseCode,
                },
            });
            if (responseVerificacion.data === 'La bodega esta inactiva') {
                return 'La bodega esta inactiva';
            }
            return true;
        } catch (error) {
            return 'Error en la validación';
        }
    },
});

/*Validación para el campo de texto
* Valida que la bodega tenga una relación con el usuario WASOLUTI y este activa esa relación.
*/
extend('usuarioWasoluti', {
    validate: async (value) => {
        const valorRecortado = value.trim();
        const [codBode] = valorRecortado.split(' - ');
        try {
            const respuestaUsuario = await 
                Vue.prototype.$http.get('msa-administration/inRbous/validarCondicion', {
                params: {
                    codBode,
                    codEmpr: enterpriseCode,
                },
            });
            if (respuestaUsuario.data === 'La bodega no esta relacionada al usuario WASOLUTI') {
                return 'La bodega no esta relacionada al usuario WASOLUTI';
            } else {
                const respuestaEstado = await
                    Vue.prototype.$http.get('msa-administration/inRbous/validarEstado', {
                    params: {
                        codBode,
                        codEmpr: enterpriseCode,
                    },
                });
                if (respuestaEstado.data === 'La relación de la bodega con el usuario WASOLUTI esta inactiva') {
                    return 'La relación de la bodega con el usuario WASOLUTI esta inactiva';
                }
            }
            return true;
        } catch (error) {
            return 'Error en la validación';
        }
    }
})

export default {
    components: {
        ValidationProvider,
    },

    /**
    * Comentario explicando la funcionalidad de elegir uno o dos parámetros de búsqueda
    * Componente para seleccionar una bodega relacionada.
    * @prop {Object} parametrosDeBusqueda - Parámetros de búsqueda. Permite configurar qué parámetros se
    * utilizarán en la búsqueda.
    * - codBode: Indica si se utilizará el código de bodega en la búsqueda.
    * - nomBode: Indica si se utilizará el nombre de bodega en la búsqueda.
    * Si la definición está así :parametrosDeBusqueda="{ codBode: '', nomBode: '' }" se ejecutará la búsqueda por ambos parámetros.
    * Si se coloca codBode:'valor1', se deberá colocar nomBode: '', de este modo buscará sólo por codBode.
    * Si se coloca nomBode: 'valor2', se deberá colocar codBode:'', de este modo buscará sólo por nomBode.
    */
    props: {
        parametrosDeBusqueda: {
            type: Object,
            required: true,
        },
        codBodeSeleccionado: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            buscarTexto: '',
            selectedBodega: '',
            buscarTextoError: '',
            bodegas: [],
            itemSeleccionado: null,
            codBodeSeleccionadoLocal: this.codBodeSeleccionado,
            noHayItemSeleccionado: false,
            HayItemSeleccionado: false,
            mostrarLista: false,
            empresaCode: null,
        };
    },
 
    watch: {
        buscarTexto(newText, oldText) {
            if (newText === '' && oldText !== '') {
                // El campo estaba lleno y ahora está vacío, puedes emitir un evento aquí
                this.$emit('campo-vacio');
            }
        }
    },

    created() {
        // Guardar el valor en la variable externa
        enterpriseCode = this.enterprise.code;
    },

    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),

        // Filtra las bodegas en base al texto de búsqueda
        bodegasFiltradas() {
            if (this.buscarTexto) {
                const valorDeBusqueda = this.buscarTexto.replace(/-|\s/g, '').toLowerCase(); // Transformar y normalizar el texto de búsqueda

                return this.bodegas.filter(bodega => {
                    const textoDeBodega = bodega.text.replace(/-|\s/g, '').toLowerCase(); // Transformar y normalizar el texto de la bodega

                    return new RegExp(`.*${valorDeBusqueda}.*`).test(textoDeBodega);
                });
            } else {
                return this.bodegas;
            }
        },
    },

    methods: {

        /**
         * Función de filtro personalizada para determinar si un elemento coincide con el texto de búsqueda.
         * @param {Object} item - Elemento de la lista.
         * @param {string} queryText - Texto de búsqueda.
         * @param {string} itemText - Texto del elemento.
         * @returns {boolean} - Indica si el elemento coincide con el texto de búsqueda.
         */
        customFilter(item, queryText, itemText) {
            const hasMatch = new RegExp(`\\b${queryText}`, 'i').test(itemText);
            const isNoResultsItem = item.value === null;
            return hasMatch || isNoResultsItem;
        },

        /**
        * Selecciona una bodega de la lista y establece el texto seleccionado en el campo de búsqueda.
        * @param {Object} bodega - Bodega seleccionada.
        * Evento que obtiene del componente padre el código de la bodega seleccionada.
        */
        seleccionDeBodega(bodega) {
            if (bodega.text === 'No se encontraron resultados') {
                this.noHayItemSeleccionado = true;
                this.mostrarLista = false;
                this.$emit('bodega-seleccionada', null);
                return;
            }

            this.noHayItemSeleccionado = false;
            this.itemSeleccionado = bodega;
            this.buscarTexto = bodega.text.trim();
            this.valueBodegaSeleccionada = bodega.value;
            this.$emit('bodega-seleccionada', parseInt(this.valueBodegaSeleccionada));
            this.mostrarLista = true;
        },

        /**
        * Realiza la búsqueda de bodegas según los parámetros de búsqueda especificados.
        * Si se ingresa texto en el campo de búsqueda, realiza la solicitud HTTP con los parámetros de búsqueda.
        * Si no se ingresa texto, vacía la lista de bodegas.
        */
        buscar() {
            this.buscarTextoError = '';
            this.noHayItemSeleccionado = false;
            
            if (this.buscarTexto) {
                this.mostrarLista = false;
                const valorDeBusqueda = this.buscarTexto.replace(/-|\s/g, '');
                const params = {
                    codBode: this.parametrosDeBusqueda.codBode,
                    nomBode: this.parametrosDeBusqueda.nomBode,
                    codEmpr: this.enterprise.code, 
                };
                
                if (this.parametrosDeBusqueda.codBode && this.parametrosDeBusqueda.nomBode) {
                    params.codBode = valorDeBusqueda;
                    params.nomBode = valorDeBusqueda;
                    params.codEmpr = this.enterprise.code;
                } else if (this.parametrosDeBusqueda.codBode) {
                    params.codBode = valorDeBusqueda;
                    params.codEmpr = this.enterprise.code;
                } else if (this.parametrosDeBusqueda.nomBode) {
                    params.nomBode = valorDeBusqueda;
                    params.codEmpr = this.enterprise.code;
                }
                this.$http
                    .get('msa-administration/inBodeg/listarBodegas', { params })
                    .then((response) => {
                        const bodegas = response.data.map((item) => ({
                            text: `${item.codBode} - ${item.nomBode}`,
                            value: `${item.codBode}`,
                        }));
                        
                        const existeValorBusqueda = bodegas.some((bodega) => {
                            const valorFormateado = bodega.text.replace(/-|\s/g, '').toLowerCase();
                            return valorFormateado.includes(valorDeBusqueda.toLowerCase());
                        });

                        this.bodegas = existeValorBusqueda ? bodegas : [];
                        this.mostrarLista = existeValorBusqueda;

                        if (this.itemSeleccionado && !bodegas.find((bodega) => bodega.value === this.itemSeleccionado.value)) {
                            this.itemSeleccionado = null;
                            this.buscarTexto = '';
                            this.codBodeSeleccionadoLocal = null;
                        }

                        if (!existeValorBusqueda) {
                            this.buscarTextoError = 'No se encontraron resultados para la búsqueda';
                        }
                    })
                    .catch((error) => {
                        console.log('Error al obtener las bodegas: ', error);
                    });
            } else {
                this.bodegas = [];
                this.mostrarLista = false;
                this.itemSeleccionado = null;
                this.codBodeSeleccionadoLocal = null;
            }
        },


        /**
        * Reinicia el texto de búsqueda.
        */
        restablecerTextDeBusqueda() {
            this.buscarTexto = '';
            this.buscarTextoError = '';
        },
    },
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.resultadoLista {
    max-height: 10rem;
    overflow-y: auto;
    z-index: 999;
    width: 100%;
}

.seleccionada {
    background-color: #e0e0e0;
}
</style>